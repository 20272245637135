import styled from 'styled-components';

export const Wrapper = styled.div`
  .block {
    padding: 6em 0;
    position: relative;
    overflow: hidden;
  }

  .block.hasBg {
    padding: 0 0 12em 0;
    overflow: hidden;
  }

  .block.hasBg:before {
    content: '';
    position: absolute;
    height: 220%;
    background: linear-gradient(to bottom, #fff 0%, #f4f9fd 50%, #fff 100%);
    left: -20%;
    right: -20%;
    top: 0;
    margin: auto;
    z-index: -1;
  }

  .block.hasBg:after {
    background: transparent url(/wave-basic.svg) top center no-repeat;
    -webkit-background-size: 5223px 186px;
    background-size: 5223px 186px;
    bottom: 0;
    content: '';
    display: block;
    height: 152px;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;
