import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Head from 'components/head';
// import Logo from 'components/logo';
import Title from 'components/title';
import Block from 'components/block';
import styled from 'styled-components';
import MarkdownWrapper from 'components/markdownwrapper';
import Img from 'gatsby-image/withIEPolyfill';
import MEDIA from 'helpers/mediaTemplates';

export const HeroBg = styled.div`
  margin-top: -4rem;
  position: relative;
  height: 50vh;
  min-height: 400px;
  overflow: hidden;

  ${MEDIA.MIN_TABLET`
    min-height: 300px;
  `};

  ${MEDIA.MIN_DESKTOP`
    min-height: 700px;
  `};
`;

export const BtmList = styled.ul`
  display: flex;
  flex-direction: column;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
  `};

  li {
    flex-basis: auto;
  }

  li + li {
    margin-left: 2rem;
  }
`;

const Services = ({ data }) => (
  <Layout>
    <Head pageTitle={data.servicesJson.title} />
    <HeroBg>
      <Img
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
        fluid={data.heroBg.childImageSharp.fluid}
        alt="Wing of airplane in flight"
        objectPosition="50% 50%"
      />
    </HeroBg>
    <div style={{ height: '5vh' }} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div style={{ textAlign: 'center' }}>
            {/* <Logo /> */}
            <Title as="h1" size="large">
              {data.servicesJson.title}
            </Title>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    <Block>
      <Container>
        <Row>
          <Col col={12} sm={12}>
            <MarkdownWrapper>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.servicesJson.content.childMarkdownRemark.html,
                }}
              />
            </MarkdownWrapper>
          </Col>
        </Row>
      </Container>
    </Block>
    <div style={{ height: '5vh' }} />
    <Container>
      <BtmList>
        <li>
          <Link to="/services" activeClassName="active">
            Services
          </Link>
        </li>
        <li>
          <Link to="/services/charter" activeClassName="active">
            Private Air Charter
          </Link>
        </li>
        <li>
          <Link to="/services/aircraft-maintenance" activeClassName="active">
            Aircraft Maintenance
          </Link>
        </li>
        <li>
          <Link to="/services/facility" activeClassName="active">
            Facility
          </Link>
        </li>
      </BtmList>
    </Container>
    <div style={{ height: '5vh' }} />
  </Layout>
);

Services.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Services;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1366, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query ServicesQuery {
    servicesJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    heroBg: file(relativePath: { eq: "services/IMG_6071A.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 3360, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
