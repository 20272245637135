import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './block.css';

const Block = ({ children, bg }) => {
  return <Wrapper bg={bg}>{children}</Wrapper>;
};

Block.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
};

export default Block;
